:root {
  --even: #424242;
  --odd: #292E33;
}


html,
body {
  height: 100%;
  background-color: #292E33;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Sans-serif;
  font-weight: 100;
}

.table-container {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: auto;
  height: 80vh;
}

.pagination {
  position: fixed;
  bottom: 0;
  padding: 10px;
  background-color: #292E33;
}


table {
  min-width: 800px;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

th {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  /* min-width: 200px; */
}

td {
  padding: 1px 15px 1px 15px;
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
}

td {
  border-right: 1px solid #040D12;
  border-left: 1px solid #040D12;
}

/* th {
  border-bottom: 1px solid #040D12;
} */

th {
  text-align: left;
}

thead th {
  background-color: #3e4247;
  position: sticky;
  top: 0;
  color: white;
  padding: 10px;
  z-index: 1;
}

tbody tr:hover {
  background-color: #040D12;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* th:first-child {
  position: sticky;
  z-index: 3; 
}

td:first-child {
  position: sticky;
  left: 0;
  z-index: 1; 
} */

th:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 3;
}

td:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 1;
}

td:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0.1px;
  height: 100%;
  background-color: #040D12;
  z-index: 1;
}

th {
  border: 1px solid #000000;
}

tbody tr:nth-child(odd) td {
  background-color: var(--odd);
}

tbody tr:nth-child(even) td {
  background-color: var(--even);
}

tbody tr:nth-child(odd) {
  background-color: var(--odd);
}

tbody tr:nth-child(even) {
  background-color: var(--even);
}

tbody tr:hover {
  background-color: #040D12;
}

tbody tr:hover td {
  background-color: #040D12;
}

td:first-child,
th:first-child {
  min-width: 0;
}

td:not(:first-child),
th:not(:first-child) {
  min-width: 200px;
}

.raise_issue {
  cursor: pointer;
  outline: none;
  padding: 10px;
  border-radius: 10px;
  font-family: monospace;
  background: linear-gradient(40deg, #BDC3C7, #96aac0);
  border: 0px;
}

.raise_issue:hover {
  background: linear-gradient(40deg, #4b96c7, #042a51);
}

.raise_issue:focus {
  border: 0.5px solid black;
}